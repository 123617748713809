import React, { Dispatch, SetStateAction, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { FormControl, FormLabel } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { CircularProgress, Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AdditionalInfo } from './../additionalInfo/AdditionalInfo'
import Alert from '@material-ui/lab/Alert'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import firebase from '../../../../../../firebase'
import './FormOffer.scss'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CancelIcon from '@material-ui/icons/Cancel'
// import type { Offer } from '../../../../../../types'
import type { Offer, OfferInfo } from '../../../../../../types'
import { text } from '../../../../../../language'

import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '36ch',
      },
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '36ch',
    },
    actionButtons: {
      marginRight: theme.spacing(1),
    },
    thumbnailContainer: {
      marginTop: 5,
      display: 'flex',
      flexWrap: 'wrap',
    },
    thumbnailWrapper: {
      position: 'relative',
    },
    imageThumbnails: {
      height: 80,
      width: 156,
      objectFit: 'cover',
      margin: 2,
      border: '1px solid #eee',
    },
    removeImage: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
  }),
)

function FormOffer({
  setPreview,
  offer,
  setOffer,
  save,
}: {
  setPreview: Dispatch<SetStateAction<boolean>>
  offer: Offer
  setOffer: Dispatch<SetStateAction<Offer>>
  save: () => Promise<void>
}) {
  const {
    title,
    description,
    discountPrice,
    normalPrice,
    category,
    additionalInfo,
    images,
  } = offer

  const [loading, setLoading] = useState<boolean>(false)
  const [warning, setWarning] = useState<boolean>(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    onSave()
  }

  const onSave = () => {
    setLoading(true)
    save().finally(() => {
      setLoading(false)
    })
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  // 1 pak images uit offer.
  // 2 maak een kopie van offer en voeg image toe
  // 3 Stop het in images in een nieuwe offer
  // [...offer.images, image] voeg image toe pakt de images uit offer en voeg
  // ({ ...offer, images: [...offer.images] })) en stopt het in images in een nieuw offer (de kopie ervan)
  const addImage = (image: string) => {
    setOffer((offer: Offer) => ({ ...offer, images: [...offer.images, image] }))
  }

  const removeImage = (image: string) => {
    setOffer((offer: Offer) => ({
      ...offer,
      images: offer.images.filter((i) => i !== image),
    }))
  }

  const setDescription = (desc: string) => {
    setOffer((offer: Offer) => ({ ...offer, description: desc }))
  }

  const setTitle = (title: string) => {
    setOffer((offer: Offer) => ({ ...offer, title }))
  }

  const setDiscountPrice = (discountPrice: number) => {
    setOffer((offer: Offer) => ({ ...offer, discountPrice }))
  }

  const setNormalPrice = (normalPrice: number) => {
    setOffer((offer: Offer) => ({ ...offer, normalPrice }))
  }

  const setCategory = (category: string) => {
    setOffer((offer: Offer) => ({ ...offer, category }))
  }
  // Type van SetStateAction<S>:
  // S of
  // een arrowfunctie met argument S die S teruggeeft
  const setInfo = (fn: SetStateAction<OfferInfo[]>) => {
    setOffer((offer: Offer) => ({
      ...offer,
      additionalInfo:
        typeof fn === 'function' ? fn(offer.additionalInfo || []) : fn,
    }))
  }

  // TODO:
  // addInfo en removeInfo als property meegeven aan AdditionalInfo component. offer.additionalInfo ook meegegeven aan AdditionalInfo (maar wel eerst hernoemen naar offer.info)
  const addInfo = (info: OfferInfo) => {
    setOffer((offer: Offer) => ({
      // kopieer alle properties van offer
      ...offer,
      // pak de array, kopieer hem en voeg info toe
      // Null check oplossen door || []
      additionalInfo: [...(offer.additionalInfo || []), info],
    }))
  }

  const removeInfo = (info: OfferInfo) => {
    setOffer((offer: Offer) => ({
      // Make copy of all props in offer
      ...offer,
      // filter all elements that are item and return that new array
      additionalInfo: (offer.additionalInfo || []).filter((e) => e !== info),
    }))
  }
  // TODO: remove additional info
  // 1e optie: filter
  // 2 optie slice maakt kopie van array zeflde als (...) en dan delete

  let storage = firebase.storage()
  let storageRef = storage.ref()

  // TODO change getElementById and addEventListiner to react standards
  function uploadFiles() {
    const fileSelector: HTMLElement | null = document.getElementById(
      'upload-photo',
    )

    if (fileSelector) {
      fileSelector.onchange = (event: any) => {
        let user = firebase.auth().currentUser

        if (!user) return
        let companyId = user.uid
        const fileList = event.target.files
        var file = fileList[0]
        if (!file) return
        if (file.size > 2621440) {
          console.log('file is too big')
          setWarning(true)
          return
        } else {
          setWarning(false)
        }
        let uuid = uuidv4()
        let filename = companyId + '-' + uuid
        let ref = storageRef.child('images/' + filename)
        ref
          .put(file)
          .then(function (snapshot) {
            console.log('Succesfully uploaded a file!')
            return snapshot.ref.getDownloadURL()
          })
          .then(function (downloadURL) {
            addImage(downloadURL)
          })
      }
    }
  }

  const classes = useStyles()
  return (
    <div>
      <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        <FormLabel>Offer details</FormLabel>
        <FormControl required fullWidth variant="outlined">
          <InputLabel htmlFor="component-outlined">{text.title}</InputLabel>
          <OutlinedInput
            id="component-outlined-title"
            label="title"
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl required fullWidth variant="outlined">
          <InputLabel htmlFor="component-outlined">
            {text.normalPrice}
          </InputLabel>
          <OutlinedInput
            id="outlined-number"
            type="number"
            label="Normal price"
            name="normalPrice"
            value={normalPrice}
            onChange={(e) =>
              setNormalPrice((e.target.value as unknown) as number)
            }
          />
        </FormControl>
        <FormControl required fullWidth variant="outlined">
          <InputLabel htmlFor="component-outlined">
            {text.discountPrice}
          </InputLabel>
          <OutlinedInput
            id="outlined-number"
            type="number"
            label="Discount price"
            name="discountPrice"
            value={discountPrice}
            onChange={(e) =>
              setDiscountPrice((e.target.value as unknown) as number)
            }
          />
        </FormControl>
        <FormControl required fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            {text.category}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            type="text"
            label="category"
            name="category"
            value={category}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setCategory(e.target.value as string)
            }
          >
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>
            <MenuItem
              value={
                'Advies en Consultancy - Accountant, boekhouder, notaris, etc'
              }
            >
              Advies en Consultancy - Accountant, boekhouder, notaris, etc
            </MenuItem>
            <MenuItem value={'Automotive'}>Automotive</MenuItem>
            <MenuItem value={'Beauty - kapper, nagel, etc'}>
              Beauty - kapper, nagel, etc
            </MenuItem>
            <MenuItem value={'Beveiliging'}>Beveiliging</MenuItem>
            <MenuItem value={'Boeken'}>Boeken</MenuItem>
            <MenuItem
              value={
                ' Bouw, Installatie & Techniek - loodgieter, stucadoor, etc'
              }
            >
              Bouw, Installatie & Techniek - loodgieter, stucadoor, etc
            </MenuItem>
            <MenuItem value={'Communicatie & Advies'}>
              Communicatie & Advies
            </MenuItem>
            <MenuItem value={'Cultuur'}>Cultuur</MenuItem>
            <MenuItem value={'Dans'}>Dans</MenuItem>
            <MenuItem value={'Diensten'}>Diensten</MenuItem>
            <MenuItem value={'Entertainment'}>Entertainment</MenuItem>
            <MenuItem
              value={
                'Eten & Drinken - restaurant, cafe, catering, produkten, etc.'
              }
            >
              Eten & Drinken - restaurant, cafe, catering, produkten, etc.
            </MenuItem>
            <MenuItem value={'Fiancieel'}>Fiancieel</MenuItem>
            <MenuItem value={'Film'}>Film</MenuItem>
            <MenuItem value={'Fotografie & Video'}>Fotografie & Video</MenuItem>
            <MenuItem value={'Gezondheid & Welzijn'}>
              Gezondheid & Welzijn
            </MenuItem>
            <MenuItem value={'Grafisch Design'}>Grafisch Design</MenuItem>
            <MenuItem value={'ICT'}>ICT</MenuItem>
            <MenuItem value={'Juridisch'}>Juridisch</MenuItem>
            <MenuItem value={'Kids'}>Kids</MenuItem>
            <MenuItem value={'Kunst'}>Kunst</MenuItem>
            <MenuItem value={'Marketing'}>Marketing</MenuItem>
            <MenuItem value={'Media'}>Media</MenuItem>
            <MenuItem value={'Mode'}>Mode</MenuItem>
            <MenuItem value={'Muziek'}>Muziek</MenuItem>
            <MenuItem
              value={'Onderwijs & Training - coaching, training, workshops'}
            >
              Onderwijs & Training - coaching, training, workshops
            </MenuItem>
            <MenuItem value={'Spiritueel'}>Spiritueel</MenuItem>
            <MenuItem value={'Sport'}>Sport</MenuItem>
            <MenuItem value={'Toerisme - reizen, recreatie, etc'}>
              Toerisme - reizen, recreatie, etc
            </MenuItem>
            <MenuItem
              value={' Transport & Opslag - taxi, koerier, vracht, etc'}
            >
              Transport & Opslag - taxi, koerier, vracht, etc
            </MenuItem>
            <MenuItem value={'Tuin'}>Tuin</MenuItem>
            <MenuItem value={'Uitvaart'}>Uitvaart</MenuItem>
            <MenuItem value={'Werving & Selectie'}>Werving & Selectie</MenuItem>
          </Select>
        </FormControl>
        <FormControl required fullWidth>
          <label htmlFor="upload-photo">
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
            />

            <Button onClick={uploadFiles} variant="contained" component="span">
              {text.uploadPicture}
            </Button>
          </label>
          <div className={classes.thumbnailContainer}>
            {images.map((image) => {
              return (
                <div key={image} className={classes.thumbnailWrapper}>
                  <img
                    className={classes.imageThumbnails}
                    src={image}
                    alt=""
                  ></img>
                  <div className={classes.removeImage}>
                    <CancelIcon
                      color="secondary"
                      onClick={() => removeImage(image)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </FormControl>
        <Collapse in={warning}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWarning(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {text.fileToLarge}
          </Alert>
        </Collapse>
        <FormControl required fullWidth variant="outlined">
          <InputLabel htmlFor="component-outlined">
            {text.description}
          </InputLabel>
          <OutlinedInput
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={4}
            type="text"
            name="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>

        {additionalInfo && (
          <AdditionalInfo
            info={additionalInfo}
            addInfo={addInfo}
            removeInfo={removeInfo}
            setInfo={setInfo}
          />
        )}
        <Button
          className={classes.actionButtons}
          variant="contained"
          color="primary"
          type="submit"
        >
          {loading ? <CircularProgress size={14} /> : 'Save'}
        </Button>
        {isMobile && (
          <Button
            className={classes.actionButtons}
            variant="outlined"
            color="primary"
            onClick={() => {
              setPreview((preview) => !preview)
              return false
            }}
          >
            {text.preview}
          </Button>
        )}
      </form>
    </div>
  )
}

export default FormOffer
