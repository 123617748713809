import 'date-fns'

import React, { useState, useContext, useEffect, SetStateAction } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Redirect, withRouter } from 'react-router'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Grid from '@material-ui/core/Grid'
import { Button, FormLabel } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { AuthContext } from './../../Authentication'
import { Profile, OpeningTimes } from '../../types'
import { OpeningTimesTable, fixTimes } from './OpeningTimes'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CancelIcon from '@material-ui/icons/Cancel'
import Alert from '@material-ui/lab/Alert'

import firebase from '../../firebase'
import { text } from '../../language'

const db = firebase.firestore()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    row: {
      '& > *': {
        margin: theme.spacing(1),
      },
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    button: {
      margin: 7,
    },
    thumbnailContainer: {
      marginTop: 5,
      display: 'flex',
      flexWrap: 'wrap',
    },
    thumbnailWrapper: {
      position: 'relative',
    },
    imageThumbnails: {
      height: 80,
      width: 156,
      objectFit: 'cover',
      margin: 2,
      border: '1px solid #eee',
    },
    removeImage: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
  }),
)
export interface CompanyCategories {
  categories: string
}

const getProfile = (uid: string) => {
  return db
    .collection('company')
    .doc(uid)
    .get()
    .then(function (res: any) {
      const data = res.data()
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        companyName: data.companyName,
        description: data.description,
        kvkNumber: data.kvkNumber,
        openingTimes: fixTimes(data.openingTimes),
        images: data.images,
        city: data.city,
        streetName: data.streetName,
        facebookURL: data.facebookURL,
        twitterURL: data.twitterURL,
        websiteURL: data.websiteURL,
        category: data.category,
      }
    })
}

function ProfilePage() {
  const classes = useStyles()
  let user = firebase.auth().currentUser

  const [warning, setWarning] = useState<boolean>(false)

  let storage = firebase.storage()
  let storageRef = storage.ref()

  const [profile, setProfile] = useState<Profile>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    companyName: '',
    description: '',
    kvkNumber: '',
    openingTimes: fixTimes({}),
    images: [],
    city: '',
    streetName: '',
    facebookURL: '',
    twitterURL: '',
    websiteURL: '',
    category: '',
  })

  const addImage = (image: string) => {
    setProfile((profile: Profile) => ({
      ...profile,
      images: [...profile.images, image],
    }))
  }

  const removeImage = (image: string) => {
    setProfile((profile: Profile) => ({
      ...profile,
      images: profile.images.filter((i) => i !== image),
    }))
  }

  const setFirstName = (firstName: string) => {
    setProfile((profile: Profile) => ({ ...profile, firstName }))
  }

  const setLastName = (lastName: string) => {
    setProfile((profile: Profile) => ({ ...profile, lastName }))
  }

  const setEmailAddress = (emailAddress: string) => {
    setProfile((profile: Profile) => ({ ...profile, emailAddress }))
  }

  const setCompanyName = (companyName: string) => {
    setProfile((profile: Profile) => ({ ...profile, companyName }))
  }

  const setDescription = (description: string) => {
    setProfile((profile: Profile) => ({ ...profile, description }))
  }

  const setKvkNumber = (kvkNumber: string) => {
    setProfile((profile: Profile) => ({ ...profile, kvkNumber }))
  }

  // const setLogo = (logo: string) => {
  //   setProfile((profile: Profile) => ({ ...profile, logo }))
  // }

  const setCity = (city: string) => {
    setProfile((profile: Profile) => ({ ...profile, city }))
  }

  const setStreetName = (streetName: string) => {
    setProfile((profile: Profile) => ({ ...profile, streetName }))
  }

  const setFacebookURL = (facebookURL: string) => {
    setProfile((profile: Profile) => ({ ...profile, facebookURL }))
  }

  const setTwitterURL = (twitterURL: string) => {
    setProfile((profile: Profile) => ({ ...profile, twitterURL }))
  }

  const setWebsiteURL = (websiteURL: string) => {
    setProfile((profile: Profile) => ({ ...profile, websiteURL }))
  }

  const setCategory = (category: string) => {
    setProfile((profile: Profile) => ({ ...profile, category }))
  }

  useEffect(() => {
    if (!user) return
    getProfile(user.uid)
      .then((profile) => {
        setProfile(profile)
      })
      .catch(function (error: string) {
        console.log(error)
      })
  }, [user])

  const {
    firstName,
    lastName,
    emailAddress,
    companyName,
    description,
    kvkNumber,
    openingTimes,
    images,
    city,
    streetName,
    facebookURL,
    twitterURL,
    websiteURL,
    category,
  } = profile

  // action
  const setTimes = (fn: SetStateAction<OpeningTimes>) => {
    setProfile((profile) => {
      const { openingTimes } = profile
      return {
        ...profile,
        openingTimes: typeof fn === 'function' ? fn(openingTimes) : fn,
      }
    })
  }

  function uploadFiles() {
    const fileSelector: HTMLElement | null = document.getElementById(
      'upload-photo',
    )

    if (!user) return
    let companyId = user.uid
    if (fileSelector) {
      fileSelector.onchange = (event: any) => {
        const fileList = event.target.files
        var file = fileList[0]
        if (!file) return
        if (file.size > 2621440) {
          console.log('file is too big')
          setWarning(true)
          return
        } else {
          setWarning(false)
        }
        let uuid = uuidv4()
        let filename = companyId + '-' + uuid
        let ref = storageRef.child('images/' + filename)
        ref
          .put(file)
          .then(function (snapshot) {
            console.log('Succesfully uploaded a file!')
            return snapshot.ref.getDownloadURL()
          })
          .then(function (downloadURL) {
            addImage(downloadURL)
          })
      }
    }
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const { authenticated } = useContext(AuthContext)

  if (!authenticated) {
    return <Redirect to="/" />
  }

  const save = () => {
    if (!user) return
    return db.collection('company').doc(user.uid).set(profile)
  }

  return (
    <div>
      <form className={classes.root} autoComplete="off">
        <Grid container>
          <Grid item xs={12} md={4}>
            <FormGroup className={classes.row}>
              <FormLabel>{text.personalInfo}</FormLabel>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.firstName}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="firstName"
                  type="text"
                  required
                  name="firstName"
                  value={firstName || ''}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.lastName}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="lastName"
                  type="text"
                  name="lastName"
                  value={lastName || ''}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.email}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="email"
                  type="text"
                  name="email"
                  value={emailAddress || ''}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.address}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="streetName"
                  type="text"
                  name="streetName"
                  value={streetName || ''}
                  onChange={(e) => setStreetName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.city}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="city"
                  type="text"
                  name="city"
                  value={city || ''}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup className={classes.row}>
              <FormLabel>{text.companyInfo}</FormLabel>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.companyName}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="companyName"
                  type="text"
                  name="companyName"
                  value={companyName || ''}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.chamberOfCommerceNumber}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="kvkNumber"
                  type="text"
                  name="kvkNumber"
                  value={kvkNumber || ''}
                  onChange={(e) => setKvkNumber(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  {text.category}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  type="text"
                  name="category"
                  value={category}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setCategory(e.target.value as string)
                  }
                  label="category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem
                    value={
                      'Advies en Consultancy - Accountant, boekhouder, notaris, etc'
                    }
                  >
                    Advies en Consultancy - Accountant, boekhouder, notaris, etc
                  </MenuItem>
                  <MenuItem value={'Automotive'}>Automotive</MenuItem>
                  <MenuItem value={'Beauty - kapper, nagel, etc'}>
                    Beauty - kapper, nagel, etc
                  </MenuItem>
                  <MenuItem value={'Beveiliging'}>Beveiliging</MenuItem>
                  <MenuItem value={'Boeken'}>Boeken</MenuItem>
                  <MenuItem
                    value={
                      ' Bouw, Installatie & Techniek - loodgieter, stucadoor, etc'
                    }
                  >
                    Bouw, Installatie & Techniek - loodgieter, stucadoor, etc
                  </MenuItem>
                  <MenuItem value={'Communicatie & Advies'}>
                    Communicatie & Advies
                  </MenuItem>
                  <MenuItem value={'Cultuur'}>Cultuur</MenuItem>
                  <MenuItem value={'Dans'}>Dans</MenuItem>
                  <MenuItem value={'Diensten'}>Diensten</MenuItem>
                  <MenuItem value={'Entertainment'}>Entertainment</MenuItem>
                  <MenuItem
                    value={
                      'Eten & Drinken - restaurant, cafe, catering, produkten, etc.'
                    }
                  >
                    Eten & Drinken - restaurant, cafe, catering, produkten, etc.
                  </MenuItem>
                  <MenuItem value={'Fiancieel'}>Fiancieel</MenuItem>
                  <MenuItem value={'Film'}>Film</MenuItem>
                  <MenuItem value={'Fotografie & Video'}>
                    Fotografie & Video
                  </MenuItem>
                  <MenuItem value={'Gezondheid & Welzijn'}>
                    Gezondheid & Welzijn
                  </MenuItem>
                  <MenuItem value={'Grafisch Design'}>Grafisch Design</MenuItem>
                  <MenuItem value={'ICT'}>ICT</MenuItem>
                  <MenuItem value={'Juridisch'}>Juridisch</MenuItem>
                  <MenuItem value={'Kids'}>Kids</MenuItem>
                  <MenuItem value={'Kunst'}>Kunst</MenuItem>
                  <MenuItem value={'Marketing'}>Marketing</MenuItem>
                  <MenuItem value={'Media'}>Media</MenuItem>
                  <MenuItem value={'Mode'}>Mode</MenuItem>
                  <MenuItem value={'Muziek'}>Muziek</MenuItem>
                  <MenuItem
                    value={
                      'Onderwijs & Training - coaching, training, workshops'
                    }
                  >
                    Onderwijs & Training - coaching, training, workshops
                  </MenuItem>
                  <MenuItem value={'Spiritueel'}>Spiritueel</MenuItem>
                  <MenuItem value={'Sport'}>Sport</MenuItem>
                  <MenuItem value={'Toerisme - reizen, recreatie, etc'}>
                    Toerisme - reizen, recreatie, etc
                  </MenuItem>
                  <MenuItem
                    value={' Transport & Opslag - taxi, koerier, vracht, etc'}
                  >
                    Transport & Opslag - taxi, koerier, vracht, etc
                  </MenuItem>
                  <MenuItem value={'Tuin'}>Tuin</MenuItem>
                  <MenuItem value={'Uitvaart'}>Uitvaart</MenuItem>
                  <MenuItem value={'Werving & Selectie'}>
                    Werving & Selectie
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.description}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="description"
                  multiline
                  rows={5}
                  type="text"
                  name="description"
                  value={description || ''}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl required fullWidth>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                  />

                  <Button
                    onClick={uploadFiles}
                    variant="contained"
                    component="span"
                  >
                    {text.uploadPicture}
                  </Button>
                </label>
                <div className={classes.thumbnailContainer}>
                  {images.map((image) => {
                    return (
                      <div key={image} className={classes.thumbnailWrapper}>
                        <img
                          className={classes.imageThumbnails}
                          src={image}
                          alt=""
                        ></img>
                        <div className={classes.removeImage}>
                          <CancelIcon
                            color="secondary"
                            onClick={() => removeImage(image)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </FormControl>
              <Collapse in={warning}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarning(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Dit bestand is te groot, de maximale grootte is 2.5MB
                </Alert>
              </Collapse>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup
              className={classes.row}
              style={{ marginLeft: isMobile ? '0px' : '10px' }}
            >
              <OpeningTimesTable times={openingTimes} setTimes={setTimes} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup className={classes.row}>
              <FormLabel>Social media</FormLabel>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">Facebook</InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="facebook"
                  type="text"
                  name="facebook"
                  value={facebookURL || ''}
                  onChange={(e) => setFacebookURL(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">Twitter</InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="twitter"
                  type="text"
                  name="twitter"
                  value={twitterURL || ''}
                  onChange={(e) => setTwitterURL(e.target.value)}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-outlined">Website</InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="website"
                  type="text"
                  name="website"
                  value={websiteURL || ''}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={save}
        >
          {text.save}
        </Button>
      </form>
    </div>
  )
}

export default withRouter(ProfilePage)
