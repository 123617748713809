import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { Redirect, useHistory } from 'react-router-dom'
import FormOffer from './formOffer/FormOffer'

import type { Offer } from '../../../../../types'

import PreviewOffer from './previewOffer/PreviewOffer'

import { Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import firebase from '../../../../../firebase'
import { AuthContext } from './../../../../../Authentication'

import './MakeOffer.scss'
const db = firebase.firestore()

export default function MakeOffer() {
  const history = useHistory()

  const [offer, setOffer] = useState<Offer>({
    title: '',
    description: '',
    discountPrice: 0,
    normalPrice: 0,
    sales: 0,
    images: [],
    category: '',
    additionalInfo: [
      {
        title: '',
        description: '',
      },
    ],
  })

  let user = firebase.auth().currentUser
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('xs'),
    { noSsr: true },
  )
  const [preview, setPreview] = useState<boolean>(!isMobile)
  const { authenticated } = useContext(AuthContext)

  if (!authenticated) {
    return <Redirect to="/" />
  }

  if (!user) return
  let companyId = user.uid
  // arrow function
  const save = async () => {
    const data = { ...offer, companyId }
    await db.collection('offer').doc().set(data)
    history.push('/offers')
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormOffer {...{ offer, setOffer, setPreview, save }} />
        </Grid>
        {preview && (
          <Grid item xs={12} sm={6}>
            <PreviewOffer {...{ offer }} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}
