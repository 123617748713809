import firebase from 'firebase/app'
// import firebaseui from 'firebaseui'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

let firebaseConfig = require('./env.json')

const config = {
  apiKey: firebaseConfig.config.apikey,
  authDomain: firebaseConfig.config.authdomain,
  databaseURL: firebaseConfig.config.databaseurl,
  projectId: firebaseConfig.config.projectid,
  storageBucket: firebaseConfig.config.storagebucket,
  messagingSenderId: firebaseConfig.config.messagingsenderid,
  appId: firebaseConfig.config.appid,
}

// Initialize Firebase
firebase.initializeApp(config)

export default firebase
