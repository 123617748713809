import 'date-fns'
import React, {useContext} from 'react'
import {Redirect, withRouter} from 'react-router'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import {Button, FormLabel} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {AuthContext} from './../../Authentication'
import firebase from '../../firebase'
import {availableTranslations, setLanguage, text} from "../../language";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '50%',
            '& > *': {
                marginBottom: theme.spacing(5),
                '& > *': {
                    margin: theme.spacing(1),
                    '& > *': {
                        marginBottom: theme.spacing(2),
                    }
                }
            },
        },
        mobileRoot: {
            width: '80%',
            '& > *': {
                marginBottom: theme.spacing(2),
                '& > *': {
                    margin: theme.spacing(0.7),
                    '& > *': {
                        marginBottom: theme.spacing(1.3),
                    }
                }
            },
        },
        select: {
            minWidth: '200px'
        }
        // form: {
        //     marginBottom: '50px'
        // }
        // button: {
        //     marginTop: '25px'
        // }
    }),
)

function Settings() {
    const classes = useStyles()
    let user = firebase.auth().currentUser
    const [open, setOpen] = React.useState(false)
    const [action, setAction] = React.useState(() => {
    })
    const [message, setMessage] = React.useState('')
    const [textState, setText] = React.useState(text)
    const [oldPassword, setOldPassword] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [newEmail, setNewEmail] = React.useState('')
    const [agreeText, setAgreeText] = React.useState(textState.agree)
    const [disagreeText, setDisagreeText] = React.useState(textState.disagree)


    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(false)
    }

    const {authenticated} = useContext(AuthContext);

    if (!authenticated) {
        return <Redirect to="/"/>;
    }

    function checkPassword() {
        setMessage(textState.areYouSure);

        setAgreeText(textState.yes);
        setDisagreeText(textState.no);
        setAction(() => changePassword);
        console.log(action);
        setOpen(true)
    }

    function changePassword() {
        const email = user?.email;
        let credential = undefined
        if (email) {
            credential = firebase.auth.EmailAuthProvider.credential(
                email,
                oldPassword
            );
        }
        if (credential) {
            user?.reauthenticateWithCredential(credential).then(() => {
                user?.updatePassword(newPassword).then(function () {
                    setMessage(textState.passwordChanged);
                    setAction(undefined);
                    setAgreeText(textState.ok)
                    setDisagreeText('')
                    setOpen(true)
                }).catch(function (error) {
                    setMessage(error);
                    setAction(undefined);
                    setAgreeText(textState.ok)
                    setDisagreeText('')
                    setOpen(true)
                });
            }).catch((error) => {
                setMessage(error.message);
                setAction(() => {
                });
                setAgreeText(textState.ok)
                setDisagreeText('')
                setOpen(true)
            })
        }
    }

    function changeEmail() {
        console.log('here')
        user?.updateEmail(newEmail).then(function () {
            setMessage(textState.emailChanged);
            setAction(undefined);
            setAgreeText(textState.ok)
            setDisagreeText('')
            setOpen(true)
        }).catch(function (error) {
            setMessage(error.message);
            setAction(undefined);
            setAgreeText(textState.ok)
            setDisagreeText('')
            setOpen(true)
        });
    }


    return (
        <div className={!isMobile ? classes.root : classes.mobileRoot}>
            <form>
                <FormGroup>
                    <FormLabel>{textState.changeEmail}</FormLabel>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="component-outlined">{textState.newEmail}</InputLabel>
                        <OutlinedInput
                            id="component-outlined-title"
                            label="newpassword"
                            type="text"
                            required
                            name="newpassword"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => changeEmail()}
                    >
                        {textState.save}
                    </Button>
                </div>
            </form>
            <form>
                <FormGroup>
                    <FormLabel>{textState.changePassword}</FormLabel>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="component-outlined">{textState.currentPassword}</InputLabel>
                        <OutlinedInput
                            id="component-outlined-title"
                            label="currentpassword"
                            type="password"
                            required
                            name="currentpassword"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="component-outlined">{textState.newPassword}</InputLabel>
                        <OutlinedInput
                            id="component-outlined-title"
                            label="newpassword"
                            type="password"
                            required
                            name="newpassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={checkPassword}
                    >
                        {textState.save}
                    </Button>
                </div>
            </form>
            <form>
                <FormGroup>
                    <FormLabel>{textState.changeLanguage}</FormLabel>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                            {textState.selectLanguage}
                        </InputLabel>
                        <Select className={classes.select}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                type="text"
                                label="language"
                                name="language"
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                    setLanguage(e.target.value as string);
                                    setText(text)
                                }
                                }
                        >
                            {Object.keys(availableTranslations).map((key) => {
                                return (
                                    <MenuItem value={availableTranslations[key]}>
                                        {key}
                                    </MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
            </form>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!!disagreeText && <Button onClick={handleClose} color="primary">
                        {textState.disagree}
                    </Button>}
                    {!!agreeText && <Button
                        onClick={() => {
                            if (action !== undefined) {
                                // @ts-ignore
                                action()
                            }
                            handleClose()
                        }}
                        color="primary"
                        autoFocus
                    >
                        {textState.agree}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default withRouter(Settings);
