import React, { useState } from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import Container from '@material-ui/core/Container'
import { OffersRoute } from './components/overview/allOffers/AllOffers'
import SignIn from './components/signIn/SignIn'
import MakeOffer from './components/overview/allOffers/singleOffer/makeOffer/MakeOffer'
import ProfilePage from './components/profilePage/ProfilePage'
import { AuthProvider } from './Authentication'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import PrivateRoute from './PrivateRoute'
import Header from './components/overview/allOffers/header/Header'
import Sidebar from './components/overview/allOffers/sidebar/sidebar'
import Settings from "./components/settings/settings";

// Create a new theme using Nunito Sans
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Avenir-Book',
  },
  palette: {
    primary: {
      main: '#353333',
    },
    secondary: {
      main: '#F2F2F2',
    },
  },
})

function App() {
  let [menuIsOpen, toggleMenu] = useState<boolean>(false)
  return (
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        <div
          className="App"
          style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
        >
          <Router>
            <Sidebar toggleMenu={toggleMenu} menuIsOpen={menuIsOpen} />
            <Header toggleMenu={toggleMenu} menuIsOpen={menuIsOpen} />
            <Container
              onClick={() => (menuIsOpen ? toggleMenu(false) : '')}
              style={{
                transition: 'transform 0.4s',
                transform: menuIsOpen ? 'translate(200px)' : 'translateX(0)',
                paddingTop: theme.spacing(10),
              }}
            >
              <Switch>
                <Route exact path="/" component={SignIn} />
                <PrivateRoute path="/offers" component={OffersRoute} />
                <PrivateRoute path="/makeOffer" component={MakeOffer} />
                <PrivateRoute path="/profilePage" component={ProfilePage} />
                <PrivateRoute path="/settings" component={Settings} />
                {/* <Route path="/offers/:offerId" component={EditOffer} /> */}
              </Switch>
            </Container>
          </Router>
        </div>
      </MuiThemeProvider>
    </AuthProvider>
  )
}

export default App
