import React, { SetStateAction, Dispatch } from 'react'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TimeRow } from '../../types'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    days: {
      lineHeight: '3',
    },
  }),
)

export function TimeInputRow({
  day,
  row,
  setRow,
}: {
  day: string
  row: TimeRow
  setRow: Dispatch<SetStateAction<TimeRow>>
}) {
  const [open, close] = row
  const classes = useStyles()
  const setOpen = (open: string) => {
    setRow((row) => [open, row[1]])
  }

  const setClose = (close: string) => {
    setRow((row) => [row[0], close])
  }

  return (
    <div style={{ display: 'flex' }}>
      <Typography className={classes.days}>
        <span style={{ display: 'block', width: '75px' }}>{day}</span>
      </Typography>
      <div>
        <TextField
          type="time"
          value={open}
          onChange={(e) => {
            return setOpen(e.target.value)
          }}
        />
      </div>
      <div>
        <TextField
          type="time"
          value={close}
          onChange={(e) => {
            return setClose(e.target.value)
          }}
        />
      </div>
    </div>
  )
}
