import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {text} from "../../../../language";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 200,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
  }),
)

export default function NoOffers() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={'https://dummyimage.com/300x150/000/fff'}
          />
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography noWrap className="title" variant="subtitle2">
                  Lorem ipsum dolor sit amet
                </Typography>
                <div className="border-bottom"></div>
              </Grid>
              <Grid item>

              </Grid>
            </Grid>
            <Typography
              className="description"
              variant="body2"
              color="textPrimary"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut felis
              odio, rutrum lacinia libero et, tempus porttitor est. Proin vitae
              ipsum molestie, consequat libero eleifend, sollicitudin risus
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant="contained" color="primary">
            <Link className={classes.link} to={'/makeOffer'}>
                {text.makeYourFirstOffer}
            </Link>
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  )
}
