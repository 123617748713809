import React, { useContext } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../Authentication';
import { List } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import {text} from '../../../../language';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: 'left 0.4s',
      position: 'fixed',
      height: '100vh',
      width: '200px',
      boxShadow: '0 0 3px 0 rgba(0,0,0,0.8)',
      left: 0,
      top: 0,
      bottom: 0,
      flexGrow: 1,
      marginBottom: '16px',
      zIndex: 100,
      backgroundColor: '#353333',
      overflow: 'hidden',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    link: {
      marginLeft: theme.spacing(1),
      textDecoration: 'none',
      color: 'white',
    },
    list: {
      marginTop: theme.spacing(10),
    },
  }),
)

function Sidebar(props: {
  toggleMenu: (open: boolean) => void
  menuIsOpen: boolean
}) {
  const history = useHistory()
  const { menuIsOpen, toggleMenu } = props
  const classes = useStyles()
  // const isTablet = useMediaQuery("max-width:1400px")
  const { authenticated } = useContext(AuthContext)

  if (!authenticated) {
    return null
  }

  const navTo = (url: string): void => {
    toggleMenu(false)
    history.push(url)
  }

    return (
        <div className={classes.root} style={{left: !menuIsOpen ? '-202px' : '0'}}>
            <List className={classes.list}>
                <MenuItem onClick={() => navTo("/offers")}>
                    <LocalOfferIcon color="secondary" style={{fontSize: 15}}/>
                    <span className={classes.link}>{text.offers}</span>
                </MenuItem>
                <MenuItem onClick={() => navTo("/profilePage")}>
                    <AccountBoxIcon color="secondary" style={{fontSize: 15}}/>
                    <span className={classes.link}>{text.profile}</span>
                </MenuItem>
                <MenuItem onClick={() => navTo("/settings")}>
                    <SettingsIcon color="secondary" style={{fontSize: 15}}/>
                    <span className={classes.link}>{text.settings}</span>
                </MenuItem>
            </List>
        </div>
    )

}

export default Sidebar
