export const availableTranslations: { [key: string]: string } = {
    Nederlands: 'nl-NL',
    English: 'en-EN'
}

let selectedLanguage: string | null = window.localStorage.getItem('language');

if (!selectedLanguage) {
    selectedLanguage = navigator.language
}

let language: { [p: string]: Translation };

export function setLanguage(langCode: string): void {
    if (Object.values(availableTranslations).includes(langCode)) {
        storeLanguage(langCode)
    }
}


language = {
    'nl-NL': {
        offer: 'Aanbieding',
        offers: 'Aanbiedingen',
        profile: 'Profiel',
        description: 'Beschrijving',
        newOffer: 'Nieuwe aanbieding',
        sold: 'Verkocht',
        category: 'Categorie',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        deleteVerification: 'Weet u zeker dat u deze aanbieding wilt verwijderen?',
        agree: 'Ja',
        disagree: 'nee',
        title: 'Titel',
        normalPrice: 'Normale Prijs',
        discountPrice: 'Kortingsprijs',
        fileToLarge: 'Dit bestand is te groot, de maximale grootte is 2.5MB',
        preview: 'voorbeeld',
        additionalInfo: 'Extra informatie',
        add: 'toevoegen',
        uploadPicture: 'Foto toevoegen',
        monday: 'maandag',
        tuesday: 'dinsdag',
        wednesday: 'woensdag',
        thursday: 'donderdag',
        friday: 'vrijdag',
        saturday: 'zaterdag',
        sunday: 'zondag',
        personalInfo: 'Persoonlijke informatie',
        firstName: 'Naam',
        lastName: 'Achternaam',
        email: 'Email',
        address: 'Adres',
        companyInfo: 'Bedrijfs informatie',
        city: 'Plaats',
        companyName: 'bedrijfsnaam',
        chamberOfCommerceNumber: 'KVK-nummer',
        businessHours: 'Openingstijden',
        save: 'opslaan',
        makeYourFirstOffer: 'Maak uw eerste aanbieding',
        settings: 'Instellingen',
        changeEmail: 'Verander uw email adres ',
        currentEmail: 'Huidig email adres',
        newEmail: 'Nieuw email adres',
        changePassword: 'Verander uw wachtwoord',
        currentPassword: 'Huidig wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        no: 'Nee',
        yes: 'Ja',
        areYouSure: 'Weet u het zeker?',
        passwordChanged: 'Wachtwoord gewijzigd',
        emailChanged: 'Email adres gewijzigd',
        ok: 'OK',
        selectLanguage: 'Kies een taal',
        changeLanguage: 'Taal wijzigen',
    },
    'en-EN': {
        offer: 'Offer',
        offers: 'Offers',
        profile: 'Profile',
        newOffer: 'New Offer',
        sold: 'Sold',
        category: 'Category',
        deleteVerification: 'Are you sure you want to delete this offer?',
        add: "Add",
        additionalInfo: "AdditionalInfo",
        address: "Address",
        agree: "Agree",
        businessHours: "Business Hours",
        chamberOfCommerceNumber: "Chamber of commerce number",
        city: "City",
        companyInfo: "Company Information",
        companyName: "Company name",
        delete: "Delete",
        description: "Description",
        disagree: "Disagree",
        discountPrice: "Discount price",
        edit: "Edit",
        email: "Email",
        fileToLarge: "File too large, maximum file size is 2.5 MB",
        firstName: "First name",
        friday: "Friday",
        lastName: "Last name",
        makeYourFirstOffer: "Make your first offer",
        monday: "Monday",
        normalPrice: "Normal Price",
        personalInfo: "Personal Information",
        preview: "Preview",
        saturday: "Saturday",
        save: "Save",
        sunday: "Sunday",
        thursday: "Thursday",
        title: "Title",
        tuesday: "Tuesday",
        uploadPicture: "Upload picture",
        wednesday: "Wednesday",
        settings: "Settings",
        changeEmail: 'Change your email address',
        currentEmail: 'Current email',
        newEmail: 'New email address',
        changePassword: 'Change your password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        no: 'No',
        yes: 'Yes',
        areYouSure: 'Are you sure?',
        passwordChanged: 'Password updated',
        emailChanged: 'Email updated',
        ok: 'OK',
        selectLanguage: 'Pick a language',
        changeLanguage: 'Change language',
    }
};

let defaultLanguage = availableTranslations.English;
export let text: Translation = language[selectedLanguage || navigator.language] || language[defaultLanguage];

interface Translation {
    changeLanguage: string;
    selectLanguage: string;
    no: string;
    yes: string;
    areYouSure: string;
    emailChanged: string,
    changePassword: string;
    currentPassword: string;
    newPassword: string;
    newEmail: string
    changeEmail: string;
    currentEmail: string;
    offer: string;
    offers: string;
    profile: string;
    description: string;
    newOffer: string;
    sold: string;
    category: string;
    edit: string;
    delete: string;
    deleteVerification: string;
    agree: string;
    disagree: string;
    title: string;
    normalPrice: string;
    discountPrice: string;
    fileToLarge: string;
    preview: string;
    additionalInfo: string;
    add: string;
    uploadPicture: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    personalInfo: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    companyInfo: string;
    city: string;
    companyName: string;
    chamberOfCommerceNumber: string;
    businessHours: string;
    save: string;
    makeYourFirstOffer: string;
    settings: string;
    passwordChanged: string;
    ok: string
}

function storeLanguage(langCode: string): void {
    selectedLanguage = langCode
    window.localStorage.setItem('language', langCode)
    text = language[selectedLanguage]
}
