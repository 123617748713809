import React, { Dispatch, SetStateAction } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { OfferInfo } from '../../../../../../types'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormGroup, FormLabel } from '@material-ui/core'
import {text} from "../../../../../../language";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    row: {
      '& > *': {
        margin: theme.spacing(1) + 'px 0',
      },
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
)

// make button that add fields (die roept addInfo aan dmv onClick)
// je geeft 1 lege offerInfo mee {title: '', description: '}

// Pak info uit props (en info heeft type OfferInfo[])
export const AdditionalInfo = ({
  info,
  addInfo,
  removeInfo,
  setInfo,
}: // setAdditionalTitle,
{
  info: OfferInfo[]
  addInfo: (info: OfferInfo) => void
  removeInfo: (info: OfferInfo) => void
  setInfo: Dispatch<SetStateAction<OfferInfo[]>>
  // setAdditionalTitle: any
}) => {
  const classes = useStyles()

  const setTitle = (index: number, title: string) => {
    setInfo((info) => {
      const next = info.slice()
      next[index] = { ...next[index], title }
      return next
    })
  }

  const setDescription = (index: number, description: string) => {
    setInfo((info) => {
      // Maak copy van info
      const next = info.slice()
      // pakt de waardes van 1 OfferInfo op plek "index" uit de kopie "next" en overschrijft dan de waarde 'description'.
      next[index] = { ...next[index], description }
      return next
    })
  }

  // void betekend dat je niks teruggeeft (het returned undefined)
  // voeg fragment toe want je kan geen array teruggeven. Met eenf fragment kan je een groepe element teruggeven als 1 object
  // type dat uit setState komt is DisPatch<SetStateAction> Als we zelf een action defineren dan
  return (
    <FormControl fullWidth>
      <FormGroup className={classes.row}>
        <FormLabel></FormLabel>
        {info.map((item: OfferInfo, index: number) => {
          return (
            <React.Fragment key={index}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-outlined">Title</InputLabel>
                <OutlinedInput
                  id="component-outlined-title"
                  label="title"
                  fullWidth
                  type="text"
                  name="title"
                  value={item.title}
                  onChange={(e) => setTitle(index, e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-outlined">
                  {text.description}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  label="description"
                  type="text"
                  name="description"
                  value={item.description}
                  onChange={(e) => setDescription(index, e.target.value)}
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={() => removeInfo(item)}
                startIcon={<DeleteIcon />}
              >
                {text.delete}
                {/*this would return an onclick event onClick={removeInfo}  */}
              </Button>
            </React.Fragment>
          )
        })}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => addInfo({ title: '', description: '' })}
        >
          {text.add}
        </Button>
      </FormGroup>
    </FormControl>
  )
}
