import React, { useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import { AuthContext } from '../../../../Authentication'
import firebase from './../../../../firebase'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      left: 0,
      top: 0,
      right: 0,
      flexGrow: 1,
      marginBottom: '16px',
      zIndex: 101,
    },
    signOutButton: {
      marginLeft: 'auto',
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
  }),
)

function Header(props: {
  toggleMenu: (open: boolean) => void
  menuIsOpen: boolean
}) {
  const { toggleMenu, menuIsOpen } = props
  const classes = useStyles()
  // const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  function signOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      })
  }

  const { authenticated } = useContext(AuthContext)

  if (!authenticated) {
    return null
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          {/*{isTablet && (*/}
          <MenuIcon
            onClick={() => toggleMenu(!menuIsOpen)}
            className={classes.menuButton}
          />
          {/*)}*/}
          <span className={classes.title}>Breyty - Business</span>
          <MenuItem className={classes.signOutButton} onClick={signOut}>
            Log uit
          </MenuItem>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
