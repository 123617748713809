import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import Collapse from '@material-ui/core/Collapse'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import type { Offer } from '../../../../types'
import { DeleteOffer } from '../../allOffers/AllOffers'
import { useHistory } from 'react-router-dom'
import { text } from '../../../../language'
import './MediaCard.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      // height: 450,
    },
    media: {
      height: 200,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
    category: {
      height: 40,
    },
    description: {
      height: 70,
    },
  }),
)

// TODO: change item into offer
// Change MediaCard to OfferCard
export default function MediaCard({
  item,
  refresh,
}: {
  item: Offer
  refresh: () => Promise<void>
}) {
  const {
    title,
    description,
    // discountPrice,
    // normalPrice,
    category,
    images,
    additionalInfo,
    id,
    sales,
  } = item

  // makeSubString(description)
  // const [expanded, setExpanded] = React.useState(false)

  // const handleExpandClick = () => {
  //   setExpanded(!expanded)
  // }

  const classes = useStyles()

  const toggleShowContent = () => {
    setShowContent(!showContent)
  }

  const [open, setOpen] = React.useState(false)
  const [showContent, setShowContent] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const NewDescription = ({
    description,
    showFull,
  }: {
    description: string
    showFull: any
  }) => {
    const maxLength = 145
    if (!showFull && description.length > maxLength) {
      description = description.substring(0, maxLength) + '...'
    }
    return <span dangerouslySetInnerHTML={{ __html: description }} />
  }
  const defaultImg = 'https://dummyimage.com/600x400/000/fff'
  const img = images[0] || defaultImg

  const history = useHistory()
  const gotoEdit = () => {
    history.push('/offers/' + item.id + '/edit')
  }

  return (
    <Card className={classes.root}>
      {/* <Link className={classes.link} to={'/offers/' + item.id + '/edit'}>
        Edit
      </Link> */}
      <CardActionArea onClick={gotoEdit}>
        <CardMedia className={classes.media} image={img} />
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                gutterBottom
                noWrap
                className="title"
                variant="subtitle2"
              >
                {title}
              </Typography>
              <div className="borderBottom"></div>
            </Grid>
          </Grid>

          {/* <Grid item> */}
          <div className="prices">
            {/* <Typography className="normalPrice">{normalPrice}</Typography>
                <Typography className="discountPrice">
                  {discountPrice}
                </Typography> */}
            <Typography className="discountPrice">
              <span>
                {' '}
                {text.sold}:{sales}
              </span>
              {/* {sales && Sales: {sales}} */}
            </Typography>
          </div>
          {/* </Grid> */}
          <Typography
            className={classes.category}
            gutterBottom
            variant="subtitle2"
            color="textSecondary"
          >
            {category && (
              <i>
                {text.category}: {category}
              </i>
            )}
          </Typography>
          <Typography
            className={classes.description}
            variant="body2"
            color="textPrimary"
          >
            <NewDescription showFull={showContent} description={description} />
          </Typography>
        </CardContent>
        <Collapse in={showContent} timeout="auto" unmountOnExit>
          <CardContent>
            {additionalInfo &&
              additionalInfo.map((item) => {
                return (
                  <span key={item.title}>
                    <Typography
                      className="title"
                      variant="subtitle2"
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      className="description"
                      variant="body2"
                      gutterBottom
                      color="textSecondary"
                    >
                      {item.description}
                    </Typography>
                  </span>
                )
              })}
          </CardContent>
        </Collapse>
      </CardActionArea>
      <CardActions>
        <Button variant="contained" color="primary">
          <Link className={classes.link} to={'/offers/' + item.id + '/edit'}>
            {text.edit}
          </Link>
        </Button>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          {text.delete}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {text.deleteVerification}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {text.disagree}
            </Button>
            <Button
              onClick={() => {
                DeleteOffer(id).then(() => refresh())
                handleClose()
              }}
              color="primary"
              autoFocus
            >
              {text.agree}
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: showContent,
          })}
          onClick={() => {
            toggleShowContent()
            // handleExpandClick()
          }}
          aria-expanded={showContent}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}
