import React, { useState, useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import FormOffer from '../formOffer/FormOffer'
import type { Offer } from '../../../../../../types'

import PreviewOffer from '../previewOffer/PreviewOffer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme } from '@material-ui/core/styles'

import firebase from '../../../../../../firebase'
import { AuthContext } from '../../../../../../Authentication'

const db = firebase.firestore()

const updateOffer = (offerId: string) => {
  return db
    .collection('offer')
    .doc(offerId)
    .get()
    .then(function (res: any) {
      const data = res.data()
      return {
        title: data.title,
        description: data.description,
        images: data.images,
        discountPrice: data.discountPrice,
        normalPrice: data.normalPrice,
        additionalInfo: data.additionalInfo,
        category: data.category,
        sales: data.sales,
      }
    })
}

interface ParamTypes {
  offerId: string
}

export default function EditOffer() {
  const history = useHistory()

  let { offerId } = useParams<ParamTypes>()

  // const [offer, setOffer] = useState<Offer | null>(null)
  const [offer, setOffer] = useState<Offer>({
    title: '',
    description: '',
    discountPrice: 0,
    normalPrice: 0,
    sales: 0,
    images: [],
    category: '',
    additionalInfo: [],
  })

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('xs'),
    { noSsr: true },
  )
  const [preview, setPreview] = useState<boolean>(!isMobile)

  useEffect(() => {
    updateOffer(offerId)
      .then((offer) => {
        setOffer(offer)
      })
      .catch(function (error: string) {
        console.log(error)
      })
  }, [offerId])

  const { authenticated } = useContext(AuthContext)

  if (!offer) return <div>loading</div>

  const save = () => {
    return db
      .collection('offer')
      .doc(offerId)
      .update(offer)
      .then(() => {
        history.push('/offers')
      })
  }

  if (!authenticated) {
    return <Redirect to="/" />
  }

  // TODO
  // als offer null is dan wordt loading aangeroepen, als ie true is dan wordt ie meegeven aan formOffer

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormOffer {...{ offer, setOffer, setPreview, save }} />
        </Grid>
        {preview && (
          <Grid item xs={12} sm={6}>
            <PreviewOffer {...{ offer }} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}
