import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import type { Offer } from '../../../../../../types'

import './PreviewOffer.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }),
)

export default function PreviewOffer({ offer }: { offer: Offer }) {
  const classes = useStyles()

  const {
    title,
    description,
    discountPrice,
    normalPrice,
    images,
    category,
    additionalInfo,
  } = offer

  const defaultImg = 'https://dummyimage.com/600x400/000/fff'
  const img = images[0] || defaultImg
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <Card
      className={classes.root}
      style={{ marginTop: isMobile ? '0px' : '40px' }}
    >
      <CardMedia className={classes.media} image={img} />
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className="title" gutterBottom variant="subtitle2">
              {title}
            </Typography>
            {description && <div className="border-bottom"></div>}
          </Grid>
          <Grid item>
            <div className="prices">
              <Typography className="normalPrice">{normalPrice}</Typography>
              <Typography className="discountPrice">{discountPrice}</Typography>
            </div>
          </Grid>
        </Grid>
        <Typography
          className="category"
          gutterBottom
          variant="subtitle2"
          color="textSecondary"
        >
          {category && <i>Categorie: {category}</i>}
        </Typography>
        <Typography
          className="description"
          variant="body2"
          color="textPrimary"
          gutterBottom
        >
          {description}
        </Typography>
        {additionalInfo &&
          additionalInfo.map((item) => {
            return (
              <span key={item.title}>
                <Typography
                  className="title"
                  variant="subtitle2"
                  gutterBottom
                  color="textPrimary"
                >
                  {item.title}
                </Typography>
                <Typography
                  className="description"
                  variant="body2"
                  gutterBottom
                  color="textSecondary"
                >
                  {item.description}
                </Typography>
              </span>
            )
          })}
      </CardContent>
    </Card>
  )
}
