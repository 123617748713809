import React, { SetStateAction, Dispatch } from 'react'

import { FormLabel } from '@material-ui/core'

import { OpeningTimes, TimeRow } from '../../types'
import { TimeInputRow } from './TimeInputRow'
import { text } from '../../language'

export const defaultOpeningTimes: OpeningTimes = {
  [text.monday]: ['00:00', '00:00'],
  [text.tuesday]: ['00:00', '00:00'],
  [text.wednesday]: ['00:00', '00:00'],
  [text.thursday]: ['00:00', '00:00'],
  [text.friday]: ['00:00', '00:00'],
  [text.saturday]: ['00:00', '00:00'],
  [text.sunday]: ['00:00', '00:00'],
}

export const fixTimes = (times: {}): OpeningTimes => {
  return { ...defaultOpeningTimes, ...times }
}

export function OpeningTimesTable({
  times,
  setTimes,
}: {
  times: OpeningTimes
  setTimes: Dispatch<SetStateAction<OpeningTimes>>
}) {
  const days: string[] = [
    text.monday,
    text.tuesday,
    text.wednesday,
    text.thursday,
    text.friday,
    text.saturday,
    text.sunday,
  ]

  const setRow = (day: string, row: SetStateAction<TimeRow>) => {
    return setTimes((times: OpeningTimes) => ({
      ...times,
      [day]: typeof row === 'function' ? row(times[day]) : row,
    }))
  }

  return (
    <div>
      <FormLabel>{text.businessHours}</FormLabel>
      {days.map((day) => {
        console.log(day)
        const row = times[day] || defaultOpeningTimes[day]
        return (
          <TimeInputRow
            key={day}
            {...{ row, day }}
            setRow={(row) => setRow(day, row)}
          />
        )
      })}
    </div>
  )
}
