import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router'
import { AuthContext } from './Authentication'

// TODO: fix error
// @ts-ignore
const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { authenticated, loadingAuthState } = useContext(AuthContext)
  if (loadingAuthState) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    )
  }
  return (
    <Route
      // If user is authenticated, render RouteComponent, otherwise redirect
      {...rest}
      render={(routeProps) =>
        authenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={'/'} />
          // <Redirect
          //   to={{ pathname: '/signin', state: { prevPath: rest.path } }}
          // />
        )
      }
    />
  )
}
export default PrivateRoute
