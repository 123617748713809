import React from 'react'
import firebase from '../../firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginContainer: {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      let element = document.getElementById('loader')
      if (element) {
        element.style.display = 'none'
      }
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '/offers',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>',
}

function SignIn() {
  const classes = useStyles()
  return (
    <div className={classes.loginContainer}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <div id="loader">Loading...</div>
    </div>
  )
}

export default withRouter(SignIn)
