import React, { useEffect, useState, useContext, useCallback } from 'react'
import {
  Route,
  Switch,
  match as Match,
  withRouter,
  Redirect,
} from 'react-router-dom'
import { AuthContext } from './../../../Authentication'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import MediaCard from './mediaCards/MediaCard'
import EditOffer from './singleOffer/makeOffer/editOffer/EditOffer'

import NoOffers from './noOffers/NoOffers'

import type { Offer } from '../../../types'

import './AllOffers.scss'

import firebase from '../../../firebase'

const db = firebase.firestore()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    button: {
      marginBottom: '16px',
    },
  }),
)

export const OffersRoute = ({ match }: { match: Match }) => {
  return (
    <Switch>
      <Route path={match.url + '/:offerId/edit'} component={EditOffer} />
      <Route path={match.url + '/'} component={AllOffers} />
    </Switch>
  )
}

// TODO
// AdditionalInfo tekst boven title en description
// default title en description tonen 1x
// Na save redirect na all offers
// Bij profile en offer komt een nieuw veld companyCategories. 1 keer ophalen bij profilepage en opslaan in variabele, die continue gebruiken
// AllOffers mediacard de prijzen weghalen en daar moet een counter met hoevaak hij is verkocht.
// Openingtimes: object
// On first login, zonder nieuwe offers iets tonen met: make new offer
// Add functionality for adding multiple images to offer with caroussel

// Title/description omvormen tot alleen AdditionalInfo, want werken met meerdere arrays geeft complexiteit. Bovendien zijn er veel te veel knoppen. Het ziet er verder hetzelfde uit in de offer
// Card met caroussel wordt afgeraden door material https://material.io/components/cards#behavior

export const DeleteOffer = (id: string | undefined) => {
  return db.collection('offer').doc(id).delete()
}

const getOffers = (companyId: string): Promise<Offer[]> => {
  return db
    .collection('offer')
    .where('companyId', '==', companyId)
    .get()
    .then(function (res: any) {
      return res.docs.map((doc: any) => {
        const data = doc.data()
        return {
          id: doc.id,
          title: data.title,
          description: data.description,
          images: data.images,
          discountPrice: data.discountPrice,
          normalPrice: data.normalPrice,
          companyId: data.companyId,
          additionalInfo: data.additionalInfo,
          category: data.category,
          sales: data.sales,
        }
      })
    })
}

const AllOffers = () => {
  const classes = useStyles()
  let user = firebase.auth().currentUser

  const [offers, setOffers] = useState<Offer[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const refresh = useCallback(() => {
    if (!user) return Promise.reject('no user')
    setLoading(true)
    let companyId = user.uid
    return getOffers(companyId)
      .then((offers) => {
        setOffers(offers)
      })
      .catch(function (error: string) {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user])

  useEffect(() => {
    refresh()
  }, [refresh])

  const { authenticated } = useContext(AuthContext)

  if (!authenticated) {
    return <Redirect to="/" />
  }

  if (loading) return <div>loading</div>

  if (!offers.length) return <NoOffers />

  const items = offers.map((item) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={item.id}>
        <MediaCard item={item} refresh={refresh} />
      </Grid>
    )
  })

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        href="makeOffer"
      >
        New offer
      </Button>
      <Grid container spacing={3}>
        {items}
      </Grid>
    </div>
  )
}

export default withRouter(AllOffers)
