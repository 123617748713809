import React, { useEffect, useState } from 'react'
import firebase from './firebase'

type ContextProps = {
  user: firebase.User | null
  authenticated: boolean
  setUser: any
  loadingAuthState: boolean
}

// Context is used to propagate data to the whole application
export const AuthContext = React.createContext<Partial<ContextProps>>({})

// AuthProvider will store the authentication status
export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as firebase.User | null)
  const [loadingAuthState, setLoadingAuthState] = useState(true)
  // Update user every time the authentication status is changed in firebase
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user: any) => {
      setUser(user)
      setLoadingAuthState(false)
    })
  }, [])
  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
